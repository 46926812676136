import React from "react"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

export default function CiteModal({ open, handleClose, citations }) {

	const cx = classNames.bind(styles);
	const largeScreen = useMediaQuery('(min-width:700px)');

	const [openToast, setOpenToast] = React.useState(false)

	function copyRichText(text) {
		const listener = function(ev) {
			ev.preventDefault();
			ev.clipboardData.setData('text/html', text);
			ev.clipboardData.setData('text/plain', text);
		};
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
		setOpenToast(true)
	}

	const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  return(
    <Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth={true}
			fullScreen={!largeScreen}
		>
			<DialogTitle>Cite this article</DialogTitle>
			<DialogContent>
				<table id="citation">
					<tbody>
						{['APA', 'Chicago', 'MLA'].map((type, i) => {
							const ltype = type.toLowerCase()
							return(
								<tr onClick={() => copyRichText(citations[ltype])} key={`item${i}`}>
									<td className={cx("type")}>{type}</td>
									<td>
										<div id={ltype} dangerouslySetInnerHTML={{__html: citations[ltype]}} />
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={cx("bibtex")}>
					<div className={cx("instruction")}>Click to copy citation to clipboard.</div>
					<Button onClick={() => copyRichText(citations.bibtex)}>
						Copy BiBTeX to clipboard
					</Button>
				</div>
			</DialogContent>
			<Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message="Citation copied to clipboard."
      />
    </Dialog>
  )

}