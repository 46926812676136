import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.scss"
import { getAuthors } from "../../util"

export default function PIERspectives({ frontmatter, authors }) {
	
  const cx = classNames.bind(styles);
  const sortedAuthors = getAuthors(frontmatter.authors, authors, "th")
  console.log(sortedAuthors)
  return (
    <div className={cx("pierspectivesInfo", "printonly")}>
      <div className={cx("title")}>{frontmatter.title}</div>
      <div className={cx("bottom")}>
        <div className={cx("info")}>
          <div className={cx("number")}>No. {parseInt(frontmatter.id)}</div>
          <div className={cx("date")}>{(new Date(frontmatter.date)).toLocaleString('th-TH', {month: 'long', year: 'numeric'})}</div>
        </div>
        <div className={cx("authors")}>
          {sortedAuthors.map((author, index) => (
            <div key={index}>{author.frontmatter.title}</div>
          ))}
        </div>
      </div>
    </div>
  )
}