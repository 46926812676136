import React from "react"
import { graphql } from "gatsby"
import { seriesPlaceholder } from "../../util"
import Layout from "../../components/Layout"
import LayoutPub from "../../components/LayoutPub"
import CiteModal from "../../components/CiteModal"
import PIERspectives from "../../components/PIERspectives"

export default function PageTemplate({ pageContext: { language, availableLanguages }, data: { mdx } }) {

	const [open, setOpen] = React.useState(false)

	return (
		<Layout
			lang={language}
			availableLanguages={availableLanguages}
			mdx={mdx}
			placeholder={seriesPlaceholder[mdx.frontmatter.series]}
			collection={mdx.fields.collection}
			handleCite={mdx.fields.citations && (() => setOpen(true))}
		>
			{mdx.fields.citations &&
				<CiteModal
					open={open}
					handleClose={() => setOpen(false)}
					citations={mdx.fields.citations}
				/>
			}
			<LayoutPub
				frontmatter={mdx.frontmatter}
				collection={mdx.fields.collection}
				prebody={
          <>
            {mdx.fields.collection === "dp" && <h2>Abstract</h2>}
            {mdx.fields.collection === "pierspectives" && <PIERspectives frontmatter={mdx.frontmatter} authors={mdx.authors} />}
          </>
        }
				body={mdx.body}
				authors={mdx.authors}
				lang={language}
				relatedByTags={mdx.relatedByTags}
				relatedByTopics={mdx.relatedByTopics}
				handleCite={mdx.fields.citations && (() => setOpen(true))}
				maxTocDepth={mdx.frontmatter.maxTocDepth}
			/>
		</Layout>
	)

}

export const pageQuery = graphql`
	query PublicationQuery($id: String) {
		mdx(id: { eq: $id }) {
			body
			fields {
				draft
				collection
				shorturl
				originalPath
				citations {
					bibtex
					apa
					mla
					chicago
				}
			}
			frontmatter {
				id
				title
				subtitle
				series
				authors
				date
				updated
				preMdx
				postMdx
				jel
				cover {
					publicURL
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
						thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 160, height: 160)
					}
				}
				coverCredits
				coverLink
				hideCover
				bibliography
				topics
				tags
				layout
				fullText
				endLinks {
					icon
					label
					link
				}
				maxTocDepth
			}
			authors {
				frontmatter {
					memberid
					title
					org
					image {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
					exclude
				}
				fields {
					originalPath
					lang
				}
			}
			relatedByTags {
				fields {
					originalPath
					collection
				}
				frontmatter {
					title
					cover {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 400, height: 250)
						}
					}
				}
			}
			relatedByTopics {
				fields {
					originalPath
					collection
				}
				frontmatter {
					title
					cover {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 400, height: 250)
						}
					}
				}
			}
		}
	}
`